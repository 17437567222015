<template>
  <div class="">
    <a-modal
      v-model="show"
      :footer="null"
      :closeIcon="true"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      centered
      width="380px"
      class="confirmotpmodal"
      :class="[currentLocale]"
    >
      <div>
        <div class="goback" @click="goback()">
          <img src="@/assets/images/Left-2.svg" alt="" width="24" height="24" />
        </div>
        <div class="title">{{ lbl['otpenter'] }}</div>
        <div class="subtitle">{{ lbl['otpphonenumber'] }}</div>
        <div class="otpdetail">
          <div class="refcode">Ref. Code: {{ otpDetails.refcode }}</div>
          <div class="time">
            <img
              src="@/assets/images/restore.svg"
              alt=""
              width="16"
              height="16"
            />
            <span>{{ lbl['otpexp'] }} {{ time }}</span>
          </div>
        </div>
        <div class="otpinput">
          <a-input
            v-model="otp[item]"
            v-for="item in otplength"
            :key="item"
            :ref="'otp'"
            :maxLength="1"
            @keyup.delete="checkdel($event, item)"
            @input="checkInput($event, item)"
          />
        </div>
        <div class="reotp">
          <span @click="getOTP()">{{ lbl['otpresend'] }}</span>
        </div>
        <b-button
          variant="danger"
          class="btn-end-campaign mt-4"
          block
          @click="sendOTP()"
        >
          {{ lbl['confirm-box-confirm-button'] }}
        </b-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import Locale from '@/helper/locale.js'

export default {
  name: 'EndCampaign',
  mixins: [Mixin],
  props: {
    show: Boolean,
    otpDetails: Object,
    getOTP: Function,
    confirmOTP: Function,
    gaback: Function,
    afterClose: Function,
    currentLocale: Locale.getLocaleShort(),
  },
  data() {
    return {
      showModal: false,
      otp: [],
      profile: Account.getCacheProfile(),
      otplength: [],
    }
  },
  computed: {
    time() {
      let min = Math.floor(this.otpDetails.expireinseconds / 60)
      let sec = this.otpDetails.expireinseconds % 60
      if (sec < 10) sec = '0' + sec
      return `0${min}:${sec}`
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'end-campaign' })
  },
  created() {
    this.otplength = [...Array(6).keys()]
  },
  watch: {
    show(newVal, oldVal) {
      console.log('Prop show: ', newVal, ' | was: ', oldVal)
      if (newVal == true) {
        this.showModal = newVal
      }
    },
  },
  methods: {
    checkInput(e, index) {
      this.otp[index] = e.target.value.replace(/[^0-9]/g, '')
      if (this.otp[index] && index + 1 !== this.otplength.length) {
        this.$refs['otp'][index + 1].$el.focus()
      }
    },
    checkdel(e, index) {
      if (index - 1 !== -1) {
        this.$refs['otp'][index - 1].$el.focus()
      }
    },
    goback() {
      this.otp = []
      this.gaback()
    },
    async sendOTP() {
      await this.confirmOTP(this.otp.join(''))
      this.otp = []
    },
  },
}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.confirmotpmodal {
  .ant-modal-content {
    border-radius: 4px;
  }
}
.confirmotpmodal {
  .ant-modal-body {
    font-size: 14px;
    text-align: left;
    padding: 32px;
    .goback {
      img {
        cursor: pointer;
      }
    }
    .title {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 500;
      color: #424242;
    }
    .subtitle {
      margin-top: 8px;
      color: #757575;
    }
    .otpdetail {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .refcode {
        font-weight: 500;
        color: #616161;
      }
      .time {
        font-weight: 500;
        color: #ff5252;
        span {
          margin-left: 6px;
        }
      }
    }
    .otpinput {
      display: flex;
      justify-content: space-between;
      gap: 6px;
      margin-top: 24px;
      input {
        width: 40px;
        height: 50px;
        background-color: white;
        font-size: 18px;
        color: #616161;
        text-align: center;
      }
    }
    .reotp {
      margin-top: 24px;
      text-align: center;
      span {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #2196f3;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
